// 
// buttons.scss
//

.btn {
    color: $white;

    .mdi {
        &:before {
            margin-top: -1px;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
// 
.btn-light,.btn-white {
    color: $gray-900;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
} 
//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// 
// Button Extra Small Size
// 

.btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
}

// 
// Button labels
//

.btn-label {
    margin: -.55rem .9rem -.55rem -.9rem;
    padding: .6rem .9rem;
    background-color: rgba($gray-900,0.1);
}

.btn-label-right {
    margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
    padding: ($btn-padding-y) ($btn-padding-x);
    background-color: rgba($gray-900,0.1);
    display: inline-block;
}

/* Social Buttons */

.btn-facebook {
    color: $white !important;
    background-color: #3b5998 !important;
}
.btn-twitter {
    color: $white !important;
    background-color: #00aced !important;
}
.btn-linkedin {
    color: $white !important;
    background-color: #007bb6 !important;
}
.btn-dribbble {
    color: $white !important;
    background-color: #ea4c89 !important;
}
.btn-googleplus {
    color: $white !important;
    background-color: #dd4b39 !important;
}
.btn-instagram  {
    color: $white !important;
    background-color: #517fa4 !important;
}
.btn-pinterest  {
    color: $white !important;
    background-color: #cb2027 !important;
}
.btn-dropbox  {
    color: $white !important;
    background-color: #007ee5 !important;
}
.btn-flickr  {
    color: $white !important;
    background-color: #ff0084 !important;
}
.btn-tumblr  {
    color: $white !important;
    background-color: #32506d !important;
}
.btn-skype {
    color: $white !important;
    background-color: #00aff0 !important;
}
.btn-youtube  {
    color: $white !important;
    background-color: #bb0000 !important;
}
.btn-github  {
    color: $white !important;
    background-color: #171515 !important;
}